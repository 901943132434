import React from 'react';

import ChatPage from '../../views/Chat/ChatPage';
import PageLayout from '../../views/Layout/Page';
import MetaTags from '../../views/MetaTags';

export default function MessagesIndexPage() {
  return (
    <PageLayout pageview="messages" private>
      <MetaTags title="messages.meta.title" />
      <ChatPage />
    </PageLayout>
  );
}
